import styled from "styled-components";
import { Link } from "gatsby";
import LogoBlack from "assets/images/logo.svg";
import EnvIcon from "assets/images/envelope.svg";
import { Button } from "components/Buttons";

export const LogoWrapper = styled.div`
  width: 180px;
  position: relative;
  z-index: 3;
  display: flex;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    width: auto;
  }
`;

export const Logo = styled(Link)`
  display: inline-block;
  width: 130px;
  height: 30px;
  background-image: url(${LogoBlack});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  transition: 0.2s width ease;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 112px;
    height: 24px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) {
    width: 100px !important;
    height: 20px !important;
  }
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 1010;
  transition: 0.2s height ease;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    transform: translateY(-80px);
    z-index: -1;
    transition: 0.2s transform ease;
    opacity: 0;
  }
  &.scrolled {
    height: 60px;
    box-shadow: 0px 4px 15px -3px ${({ theme }) => theme.colors.menu_shadow};
    ${Logo} {
      width: 120px;
      height: 30px;
    }

    &:after {
      transform: translateY(0px);
      opacity: 1;
    }
  }
`;

export const Wrapper = styled.div`
  max-width: 2560px;
  width: 100%;
  margin: 0 auto;
  padding: 0 48px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 0 24px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    height: 48px;
  }
`;

export const MenuRight = styled.div`
  display: flex;
  align-items: center;
`;

export const ContactButton = styled(Button)`
  background-color: #000;
  transition: 0.2s color ease;
  padding: 12px 24px;
  background-repeat: no-repeat;
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.purple};
  color: #fff;
  border: none;
  line-height: 1;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  transition: 0.2s all ease;
  border-radius: 5px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.button_hover};
    color: #fff;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 10px 18px;
    font-size: 1.2rem;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    background-image: url(${EnvIcon});
    width: 32px;
    height: 32px;
    padding: 0;
    background-size: 16px;
    background-position: center;
    min-width: 0px;
  }
`;

export const ContactButtonText = styled.span`
  color: #fff;
  font-weight: 800;
  font-size: 1.4rem;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 1.2rem;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 1rem;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    display: none;
  }
`;

export const SocialsWrapper = styled.div`
  display: flex;
  margin-right: 12px;
`;

export const SocialLink = styled.a`
  display: block;
  width: 38px;
  height: 38px;
  margin: 0 6px;
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  border-radius: 7px;
  &:hover {
    background-image: url(${({ hover }) => hover});
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 28px;
    height: 28px;
    background-size: 20px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    width: 20px;
    height: 20px;
    background-size: 16px;
    margin: 0 4px;
  }
`;
