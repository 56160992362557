import styled, { css } from "styled-components";

const defaultText = css`
  display: block;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.8;
  color: ${({ color }) => (color ? color : "#000")};

  b {
    font-weight: 600;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 1.4rem;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 1.2rem;
  }
`;

export const Title = styled.h2`
  font-size: 3.2rem;
  line-height: 1.2;
  font-weight: 800;
  width: 100%;
  color: ${({ color }) => (color ? color : "#000")};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 2.8rem;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 2rem;
  }
`;

export const Text = styled.p`
  ${defaultText}
`;

export const ListItem = styled.li`
  padding-left: 24px;
  position: relative;
  ${defaultText}

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 12px;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.colors.purple};
  }
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    padding-left: 18px;
    &:before {
      top: 7px;
      width: 5px;
      height: 5px;
    }
  }
`;
