import styled from "styled-components";
import { SectionWrapper } from "shared/Styles/Wrappers";
import { Title, Text } from "../Typography/typography";

export const Container = styled.div`
  padding: 124px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 72px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 48px 0 24px 0;
  }
`;

export const Wrapper = styled(SectionWrapper)`
  max-width: 1000px;
  text-align: center;
  #landing_form {
    margin-top: -100px;
    padding-top: 100px;
  }
`;

export const StyledTitle = styled(Title)``;

export const StyledText = styled(Text)`
  margin: 36px 0 72px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin: 36px 0 56px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 24px 0 48px 0;
  }
`;
