import React, { useState } from "react";
import { useScrollPosition } from "shared/Hooks/useScrollPosition";

import { ContactButton, ContactButtonText, Container, Dot, Hamburger, Line, Logo, LogoWrapper, MenuItem, MenuLink, MenuList, MenuRight, MenuWrapper, Wrapper, } from "./styles";

const data_menu = [
  {
    title: "Usługi",
    slug: "/uslugi",
  },
  {
    title: "Realizacje",
    slug: "/realizacje",
  },
  {
    title: "Rozwiązania",
    slug: "/rozwiazania",
  },
  {
    title: "O nas",
    slug: "/o-nas",
  },
  // {
  //   title: "Internacjonalizacja",
  //   slug:  "/internacjonalizacja",
  // },
  {
    title: "Blog",
    slug: "/blog",
  },
  {
    title: "Kariera",
    slug: "/kariera",
  },
  {
    title: "Kontakt",
    slug: "/kontakt",
  },
];

export const NavbarNew = ({ defaultTheme, showNavbar, location }) => {
  const [menuScroll, setMenuScroll] = useState(false);
  const [menuActive, setMenuActive] = useState(false);

  useScrollPosition(({ currPos }) => {
    if (currPos.y < -60) {
      setMenuScroll(true);
    } else {
      setMenuScroll(false);
    }
  });

  const handleClick = () => {
    setMenuActive(!menuActive);
    document.body.classList.toggle("blocked");
  };

  const closeMenu = () => {
    setMenuActive(false);
    document.body.classList.remove("blocked");
  };

  return (
    <Container
      className={` ${menuScroll ? "scrolled" : ""} ${
        menuActive ? "active" : ""
      } ${showNavbar ? "show-navbar" : ""}`}
    >
      <Wrapper>
        <LogoWrapper>
          <Logo
            to="/"
            title="Logo InteractiveVision"
            className={`${menuScroll ? "scrolled" : ""} ${
              showNavbar ? "show-navbar" : ""
            }`}
            logo={defaultTheme}
          />
        </LogoWrapper>
        <MenuWrapper>
          <MenuList>
            {data_menu.map((item, i) => (
              <MenuItem key={i}>
                <MenuLink
                  activeClassName="current"
                  onClick={() => closeMenu()}
                  to={item.slug}
                  title={item.title}
                >
                  {item.title}
                  <Dot>.</Dot>
                </MenuLink>
              </MenuItem>
            ))}
          </MenuList>
        </MenuWrapper>
        <MenuRight>
          <ContactButton
            onAnchorLinkClick={() => closeMenu()}
            title="Link do formularza kontaktowego"
            to="/kontakt#formularz_kontaktowy"
            stripHash
          >
            <ContactButtonText>Napisz do nas!</ContactButtonText>
          </ContactButton>
          <Hamburger
            onClick={() => handleClick()}
            className={menuActive ? "active" : ""}
          >
            <Line/>
            <Line/>
            <Line/>
          </Hamburger>
        </MenuRight>
      </Wrapper>
    </Container>
  );
};
