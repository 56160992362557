import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {Iframe, IframeWrapper} from "./styles";

export const MODAL_CONTACT_IFRAME_NAME = "INTERVI_CONTACT_MODAL_FORM";
export const MODAL_CONTACT_PERSON_IFRAME_NAME = "INTERVI_CONTACT_PERSON_MODAL_FORM";
export const CONTACT_IFRAME_NAME = "INTERVI_CONTACT_FORM";
export const CAREER_DETAILS_IFRAME_NAME = "INTERVI_CAREER_DETAILS_FORM";
export const CAREER_IFRAME_NAME = "INTERVI_CAREER_FORM";
export const CASES_IFRAME_NAME = "INTERVI_CASES_FORM";
export const PARP_IFRAME_NAME = "INTERVI_PARP_FORM";
export const PPWB_IFRAME_NAME = "INTERVI_PPWB_FORM";

const Form = ({src, name}) => {
  const theme = useSelector((state) => state.theme.darkMode ? "dark" : "main");
  const [isFormLoaded, setFormLoaded] = useState(false);

  /** @type {MutableRefObject<HTMLIFrameElement>} */
  const iframe = useRef(null);

  useEffect(() => {
    const location = window.location.pathname;

    const handler = (event) => {
      if (!iframe.current || !event.origin.startsWith(process.env.GATSBY_EXTERNAL_FORMS_ORIGIN) || event.data.name !== name) {
        return;
      }

      if (event.data.height) {
        iframe.current.style.height = event.data.height + "px";
      }

      if (event.data.theme && theme !== event.data.theme) {
        iframe.current.contentWindow.postMessage({theme: theme}, src);
      }

      if (location.includes("realizacje")) {
        iframe.current.contentWindow.postMessage({location}, src);
      }

      if (event.data.type === "form-success") {
        const gtmEvent = {event: event.data.name};

        if (location.includes("realizacje")) {
          gtmEvent.case = location.pathname;
        }

        if (!window.dataLayer) {
          window.dataLayer = [];
        }

        window.dataLayer.push(gtmEvent);
      }

      if (event.data.offset) {
        window.scroll({
          top:      iframe.current.offsetTop + event.data.offset - 200,
          left:     0,
          behavior: "smooth",
        });
      }
    };

    window.addEventListener("message", handler);

    return () => {
      window.removeEventListener("message", handler);
    };
  }, [theme, name, src]);

  useEffect(() => {
    if (iframe.current && isFormLoaded) {
      iframe.current.contentWindow.postMessage({theme: theme}, src);
    }
  }, [theme, isFormLoaded, src]);

  return (
    <IframeWrapper className="form-wrapper">
      <Iframe
        ref={iframe}
        src={src}
        name={name}
        onLoad={() => setFormLoaded(true)}
        title="Formularz kontaktowy"
      />
    </IframeWrapper>
  );
};

export {Form};
