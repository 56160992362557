import WhiteLogo        from "assets/images/logo_white.svg";
import {Button}         from "components/Buttons";
import {Paragraph}      from "components/Typography";
import {Link}           from "gatsby";
import {SectionWrapper} from "shared/Styles/Wrappers";
import styled, {css}    from "styled-components";

export const Container = styled.div`
    max-width: 100vw;
    width: 100%;
    background-color: #0b0b0b;
    overflow: hidden;
    padding-bottom: 60px;
`;

export const Wrapper = styled(SectionWrapper)`
    color: #fff;
`;

export const Title = styled.div`
    z-index: 1;
    position: relative;
    font-size: 2.8rem;
    max-width: 480px;
    margin-left: 114px;
    color: #fff;
    font-weight: 800;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 2.4rem;
        margin-left: 24px;
    }
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        font-size: 2.4rem;
        margin-left: 0px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        font-size: 2.2rem;
    }
`;

export const TitleWrapper = styled.div`
    &.purple {
        padding: 72px 96px 72px 0;
        position: relative;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 64px;
            height: 100%;
            width: 100vw;
            background-color: ${({theme}) => theme.colors.purple};
        }

        @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
            padding: 64px 96px 64px 0;
        }
        @media screen and (max-width: ${({theme}) =>
                theme.breakpoints.tablet_big}) {
            display: flex;
            justify-content: center;
            padding: 48px 24px 48px 0;

            &::before {
                right: 0;
            }
        }
        @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
            text-align: center;
            &:before {
                width: 100vw;
                left: 50%;
                right: 0;
                transform: translateX(-50%);
            }
        }
    }
`;

export const Column = styled.div`
    width: 50%;

    &.right {
        display: flex;
        justify-content: flex-end;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        width: 100% !important;
    }
`;

export const CenteredRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 108px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        margin-top: 64px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
    }
`;

export const Row = styled.div`
    display: flex;

    &.icons-block {
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        flex-direction: column;

        &.icons-block {
            align-items: center;
        }
    }
`;

export const GoogleBadge = styled.a`
    height: 100px;
    display: inline-flex;

    img {
        width: 100%;
        height: 100%;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        height: 90px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin: 36px 0 24px 0;
        height: 100px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) and (orientation: landscape) {
        margin: 24px 0 12px;
    }
`;

export const Address = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 64px 0 64px 24px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        padding-left: 0;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        width: 100%;
        padding: 48px 0 48px 0;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        flex-direction: column;
    }
`;

export const AddressColumn = styled.div`
    width: 50%;
    min-width: 190px;
    margin-top: 24px;
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        text-align: center;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        width: 100%;

        &:nth-child(2) {
            margin-top: 24px;
        }
    }
`;

const address_styles = css`
    display: block;
    margin-bottom: 6px;
    font-size: ${({theme}) => theme.fonts.xs};
    line-height: 1.2;
    font-weight: 300;
`;

export const AddressTitle = styled.span`
    ${address_styles};
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 8px;
    width: 100%;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        text-align: center;
        @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
            width: 100%;
        }
    }
`;

export const SmallTitle = styled(AddressTitle)`
    text-align: center;
`;

export const AddressText = styled.span`
    ${address_styles};
    font-size: 1.2rem;
    color: ${({theme}) => theme.colors.gray_black};
`;

export const PhoneNumber = styled.a`
    font-weight: 600;
    margin-bottom: 24px;
    color: #fff;
    font-size: 3rem;

    span {
        font-weight: 200;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 2.8rem;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        font-size: 2.2rem;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        font-size: 1.8rem;
    }
`;

export const StyledButton = styled(Button)`
    margin-top: 30px;
`;

export const Socials = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 64px;

    a {
        font-size: 1.4rem;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        margin: 36px 0 0;
    }
`;

export const SocialWrapper = styled.div`
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;

    &:not(:nth-child(4n)) {
        margin: 0 30px;
    }

    &:nth-child(4n) {
        margin-right: 0;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        width: 24px;
        height: 24px;

        &:not(:nth-child(4n)) {
            margin: 0 12px;
        }
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        &:not(:nth-child(4n)) {
            margin: 0 12px;
        }
    }
`;

export const SocialLink = styled.a`
    display: inline-block;
    font-size: ${({theme}) => theme.fonts.xs};
    margin: 0 8px;
    transition: 0.2s color ease;

    &:hover {
        color: ${({theme}) => theme.colors.purple};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        margin-bottom: 12px;

        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }
`;

export const SocialIcon = styled.img`
    width: 28px;
    height: 28px;
    object-fit: contain;
`;

export const Copyrights = styled(Paragraph)`
    display: block;
    color: ${({theme}) => theme.colors.gray_black};
    text-align: center;
    margin-top: 36px;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 1.2rem;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        text-align: center;
    }
`;

export const FooterLogo = styled(Link)`
    width: 100px;
    height: 30px;
    background-image: url(${WhiteLogo});
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        margin-top: 36px;
    }
`;
