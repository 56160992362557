export {
  Colored,
  Paragraph,
  TitleXL,
  TitleL,
  TitleM,
  TitleS,
  TitleXS,
  TitleXXS,
} from "./styles";
