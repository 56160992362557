import styled from "styled-components";
import { GlobalWrapper, SectionWrapper } from "shared/Styles/Wrappers";
import { Button } from "components/Buttons";
import { Title, Text } from "../Typography/typography";

export const Container = styled(GlobalWrapper)`
  height: 100vh;
  min-height: 100vh;
  background-color: rgb(248, 248, 248);
  padding: 100px 48px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    min-height: 0;
    height: auto;
    padding: 100px 0 72px 0;
  }
`;

export const Wrapper = styled(SectionWrapper)`
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 1600px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    max-width: 1200px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column-reverse;
  }
`;

export const Column = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  &:nth-child(1) {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 45%;
  }
  &:nth-child(2) {
    justify-content: flex-end;
    width: 55%;
    padding: 48px 48px 48px 72px;
    .gatsby-image-wrapper {
      width: 100%;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100% !important;
    padding: 0 !important;
    &:nth-child(1) {
      text-align: center;
    }
    &:nth-child(2) {
      max-width: 400px;
      .gatsby-image-wrapper {
        width: 100%;
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    align-self: center;
  }
`;

export const StyledTitle = styled(Title)``;

export const StyledText = styled(Text)`
  margin: 48px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 36px 0;
  }
`;
