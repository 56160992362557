import styled from "styled-components";
import { Title } from "../Typography/typography";
import { Button } from "components/Buttons";

export const Container = styled.div`
  background-color: #000;
  height: calc(100vh - 60px);
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: auto;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    height: 360px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.purple};
    opacity: 0.3;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    overflow: hidden;
  }
`;

export const TextWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  color: #fff;
  text-align: center;
  max-width: 1200px;
  width: 100%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    max-width: 800px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 500px;
  }
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledTitle = styled(Title)`
  color: #fff;
  font-size: 6rem;
  line-height: 1.1;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 4rem;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 3rem;
    width: 100%;
    padding: 0 24px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 2.2rem;
  }
`;

export const StyledButton = styled(Button)`
  background-color: #000;
  margin-top: 48px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 24px;
  }
`;
