import styled from "styled-components";
import SocialIcon from "assets/images/socialmedia.svg";
import CloseBox from "assets/images/close.svg";

export const SocialsWrapper = styled.div`
  display: flex;
  margin: 80px 0;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    margin: 40px 0;
  }
  @media screen and (max-width: 813px) and (orientation: portrait) {
    flex-wrap: wrap;
  }
`;

export const SocialWrapperBox = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transform: translateY(50px);
  text-align: center;
  &.active {
    transform: translateY(0px);
    opacity: 1;
    visibility: visible;
    transition: 0.4s all ease;
    transition-delay: 0.4s;
  }
`;

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  max-width: 100vw;
  width: 124px;
  height: 124px;
  z-index: 1000;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 124px;
    height: 124px;
    border-top-right-radius: 100%;
    background-color: ${({ theme, background }) =>
      background ? background : theme.colors.socialBox};
    z-index: -1;
    opacity: 1;
    box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, 0.75);
  }
  &.active {
    width: 100%;
    height: 100vh;
    z-index: 1200;

    &:after {
      width: 300vw;
      height: 300vw;
      transition: 0.8s width ease-in, 0.8s height ease-in,
        0.2s background-color ease-in;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 100px;
    height: 100px;
    &:after {
      width: 100px;
      height: 100px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 80px;
    height: 80px;
    &:after {
      width: 80px;
      height: 80px;
    }
  }
`;

export const SocialLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;
  margin: 0 25px;
  cursor: pointer;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    margin: 0 12px;
    padding: 0 12px;
  }
  @media screen and (max-width: 813px) and (orientation: portrait) {
    width: 50%;
    padding: 12px;
    margin: 0 0 24px 0;
  }
`;

export const SocialsTitle = styled.div`
  font-size: 4rem;
  font-weight: 800;
  color: #fff;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 3.4rem;
  }
  @media screen and (max-width: 813px) and (orientation: landscape) {
    font-size: 2.6rem;
  }
`;

export const SocialImage = styled.img`
  width: 60px;
  height: 60px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 40px;
    height: 40px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    width: 34px;
    height: 34px;
  }
  @media screen and (max-width: 813px) and (orientation: landscape) {
    width: 28px;
    height: 28px;
  }
`;

export const SocialText = styled.span`
  font-size: 1.8rem;
  color: #fff;
  margin-top: 18px;
  font-weight: 700;
  display: block;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 1.4rem;
    margin-top: 14px;
  }
  @media screen and (max-width: 813px) and (orientation: landscape) {
    font-size: 1.2rem;
    margin-top: 10px;
  }
`;

export const OpenButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 36px;
  right: 42px;
  cursor: pointer;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    top: 36px;
    right: 30px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    top: 18px;
    right: 20px;
  }
`;

export const CloseButton = styled.button`
  position: fixed;
  top: 48px;
  right: 48px;
  width: 32px;
  height: 32px;
  opacity: 0;
  visibility: hidden;
  border: none;
  background-color: transparent;
  background-image: url(${CloseBox});
  background-position: center;
  background-repeat: no-repeat;
  &.active {
    opacity: 1;
    visibility: visible;
    transition: 0.2s all ease;
    transition-delay: 0.4s;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    top: 24px;
    right: 24px;
  }
`;

export const ImageBox = styled.div`
  width: 36px;
  height: 36px;
  background-image: url(${SocialIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-bottom: 12px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 24px;
    height: 24px;
    margin-bottom: 8px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 20px;
    height: 20px;
  }
`;

export const TextBox = styled.div`
  font-size: 1.2rem;
  font-weight: 800;
  color: #fff;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 1rem;
    text-align: center;
  }
`;
