import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background: ${({ theme }) => theme.colors.black};
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  height: 100vh;
  max-height: -webkit-fill-available;
`;

export const PlayerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Close = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  top: 48px;
  right: 24px;
  z-index: 100;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone_big}) and (orientation: landscape) {
    top: 20px;
    right: 20px;
  }
`;

export const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const VideoBox = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
