import styled, { css } from "styled-components";

const default_styles = css`
  color: ${({ color, theme }) => (color ? color : theme.colors.black)};
  font-weight: ${({ weight }) => (weight ? weight : 800)};
  font-size: ${({ size }) => (size ? `${size}rem` : null)};
`;

const underline_style = css`
  ${({ underline }) => {
    if (underline) {
      return css`
        display: inline-block;
        position: relative;

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 100%;
          left: 0;
          width: 70px;
          height: 3px;
          background-color: ${({ theme }) => theme.colors.purple};
        }
      `;
    }

    return "";
  }};
`;

export const Colored = styled.span`
  ${default_styles};
  color: ${({ theme, color }) => (color ? color : theme.colors.purple)};
`;

export const TitleXL = styled.h1`
  font-size: ${({ theme }) => theme.fonts.xxl};
  ${default_styles};
  ${underline_style};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 3.2rem;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 2.6rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 2.4rem;
  }
`;

export const TitleL = styled.h2`
  font-size: ${({ theme }) => theme.fonts.xl};
  ${default_styles};
  ${underline_style};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 2.8rem;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 2.2rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    font-size: 1.8rem;
  }
`;

export const TitleM = styled.h3`
  font-size: ${({ theme }) => theme.fonts.xl};
  ${default_styles};
  ${underline_style};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 3rem;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 2.4rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    font-size: 2.2rem;
  }
`;

export const TitleS = styled.h4`
  font-size: ${({ theme }) => theme.fonts.l};
  ${default_styles};
  ${underline_style};

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 2.4rem;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    font-size: 2.2rem;
  }
`;

export const TitleXS = styled.h5`
  font-size: ${({ theme }) => theme.fonts.xs};
  ${default_styles};
  ${underline_style};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 1.8rem;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 1.6rem;
  }
`;

export const TitleXXS = styled.h6`
  font-size: ${({ theme }) => theme.fonts.xs};
  ${default_styles};
  ${underline_style};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 1.2rem;
  }
`;

export const Paragraph = styled.p`
  display: inline;
  font-size: ${({ theme, size }) => (size ? `${size}rem` : theme.fonts.xs)};
  color: ${({ color, theme }) => (color ? color : theme.colors.black)};
  font-weight: ${({ weight }) => (weight ? weight : 300)};
  line-height: 1.6;
  ${underline_style};
  a {
    font-size: ${({ theme, size }) => (size ? `${size}rem` : theme.fonts.xs)};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 1.4rem;
    a {
      font-size: 1.4rem;
    }
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 1.2rem;
    a {
      font-size: 1.2rem;
    }
  }
`;
