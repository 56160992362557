import styled from "styled-components";
import ArrowIcon from "assets/images/arrow_top.svg";

export const ArrowBox = styled.div`
  position: fixed;
  bottom: 48px;
  right: 36px;
  z-index: 10;
  background-color: ${(props) => props.theme.colors.purple};
  background-image: url(${ArrowIcon});
  background-repeat: no-repeat;
  background-position: center;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s all ease;
  box-shadow: 0px 0px 5px 1px rgba(90, 28, 228, 0.55);
  border-radius: 100%;
  cursor: pointer;
  &.show {
    opacity: 1;
    visibility: visible;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    bottom: 60px;
    right: 36px;
    width: 28px;
    height: 28px;
    background-size: 12px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    right: 22px;
  }
`;
