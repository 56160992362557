import React from "react";
import {
  Container,
  Wrapper,
  Video,
  TextWrapper,
  StyledTitle,
  StyledText,
  StyledButton,
} from "./styles";

export const VideoBox = ({ data }) => {
  return (
    <Container>
      <Wrapper>
        <Video src={data.video} autoPlay muted playsInline />
        <TextWrapper>
          <StyledTitle>{data.title}</StyledTitle>
          <StyledText>{data.text}</StyledText>
          <StyledButton>Wyceń projekt</StyledButton>
        </TextWrapper>
      </Wrapper>
    </Container>
  );
};
