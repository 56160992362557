import styled from "styled-components";
import { GlobalWrapper, SectionWrapper } from "shared/Styles/Wrappers";
import { Title, Text, ListItem } from "../Typography/typography";
import { Button } from "components/Buttons";

export const Container = styled(GlobalWrapper)`
  padding: 96px 0 0 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 72px 0 0 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 48px 0 0 0;
  }
`;

export const Wrapper = styled(SectionWrapper)`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 960px;
  height: 100%;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    max-width: 800px;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  &:nth-child(1) {
    justify-content: center;
    flex-direction: column;
  }
  &:nth-child(2) {
    align-items: flex-end;
    justify-content: center;
    margin-top: 96px;
    .gatsby-image-wrapper {
      max-width: 320px;
      width: 100%;
      height: 100%;
    }
  }
`;

export const StyledTitle = styled(Title)``;

export const StyledButton = styled(Button)`
  align-self: center;
`;

export const StyledText = styled(Text)`
  margin: 48px 0;
`;

export const List = styled.ul``;

export const StyledListItem = styled(ListItem)``;

export const ListWrapper = styled.div`
  margin: 48px 0;
  text-align: left;
`;

export const ListTitle = styled(Text)`
  font-weight: 600;
  margin-bottom: 24px;
`;
