import styled from "styled-components";
import { GlobalWrapper, SectionWrapper } from "shared/Styles/Wrappers";
import { Button } from "components/Buttons";
import { Title, Text } from "../Typography/typography";

export const Container = styled(GlobalWrapper)`
  position: relative;
  padding-bottom: 124px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding-bottom: 72px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-bottom: 48px;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background-color: ${({ color }) => (color ? color : "rgba(248,248,248)")};
  }
`;

export const Wrapper = styled(SectionWrapper)`
  text-align: center;
`;

export const ImageWrapper = styled.div`
  position: relative;
  z-index: 2;
  .gatsby-image-wrapper {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const TextWrapper = styled.div`
  max-width: 940px;
  width: 100%;
  margin: 96px auto 0 auto;
`;

export const StyledTitle = styled(Title)``;

export const StyledText = styled(Text)`
  display: block;
  margin: 48px 0;
`;

export const StyledButton = styled(Button)`
  background-color: #000;
  &:hover {
    background-color: #0a0a0a;
  }
`;
