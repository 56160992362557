import styled from "styled-components";
import ThemeImg from "../../assets/images/theme.svg";

export const ThemeSwitcher = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 100;
  position: fixed;
  bottom: 100px;
  right: 32px;
  z-index: 10;
  width: 44px;
  height: 100px;
  border-radius: 40px;
  padding-top: 18px;
  background-image: url(${ThemeImg});
  background-repeat: no-repeat;
  background-position: 50% 14px;
  background-color: ${({ theme }) => theme.colors.switcher};
  box-shadow: 0px 0px 18px -5px ${({ theme }) => theme.colors.switcher_shadow};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 34px;
    height: 76px;
    background-position: 50% 10px;
    background-size: 12px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    right: 19px;
  }
`;

export const ThemeSwitcherSpan = styled.span`
  display: block;
  width: 14px;
  height: 30px;
  background-color: #e9e9e9;
  position: relative;
  cursor: pointer;
  border-radius: 20px;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -5px;
    width: 18px;
    height: 18px;
    background-color: ${({ theme }) => theme.colors.purple};
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    transition: 0.4s all ease;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 10px;
    height: 28px;
    &:before {
      width: 14px;
      height: 14px;
      top: -3px;
    }
  }
`;

export const ThemeSwitcherInput = styled.input`
  opacity: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  position: absolute;
`;

export const ThemeSwitcherLabel = styled.label`
  input:checked ~ span {
    &:before {
      top: calc(100% - 15px);
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    input:checked ~ span {
      &:before {
        top: calc(100% - 9px);
      }
    }
  }
`;
