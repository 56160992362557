import styled from "styled-components";
import { GlobalWrapper, SectionWrapper } from "shared/Styles/Wrappers";
import { Button } from "components/Buttons";
import { Title, Text } from "../Typography/typography";

export const Container = styled(GlobalWrapper)`
  position: relative;
  padding-bottom: 96px;
  background-color: rgb(248, 248, 248);
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding-bottom: 56px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-bottom: 36px;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background-color: #000;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    &:after {
      height: 100px;
    }
  }
`;

export const Wrapper = styled(SectionWrapper)`
  text-align: center;
  width: 100%;
`;

export const Video = styled.video`
  height: 100%;
  width: 100%;
  object-fit: contain;
  position: relative;
  z-index: 2;
`;

export const TextWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 96px auto 0 auto;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin: 56px auto 0 auto;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 36px auto 0 auto;
  }
`;

export const StyledTitle = styled(Title)``;

export const StyledText = styled(Text)`
  display: block;
  margin: 48px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 36px 0;
  }
`;

export const StyledButton = styled(Button)`
  background-color: #000;
  &:hover {
    background-color: #0a0a0a;
  }
`;
