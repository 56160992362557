import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "store/theme";

import {
  ThemeSwitcher,
  ThemeSwitcherLabel,
  ThemeSwitcherInput,
  ThemeSwitcherSpan,
} from "./styles";

export const Switcher = () => {
  const themeState = useSelector((state) => state.theme.darkMode);
  const dispatch = useDispatch();

  const toggleMode = () => {
    dispatch(toggleTheme());
  };

  return (
    <ThemeSwitcher>
      <ThemeSwitcherLabel>
        <ThemeSwitcherInput
          type="checkbox"
          onChange={toggleMode}
          checked={themeState}
        />
        <ThemeSwitcherSpan />
      </ThemeSwitcherLabel>
    </ThemeSwitcher>
  );
};
