import React, { useState } from "react";
import { useScrollPosition } from "shared/Hooks/useScrollPosition";
import { data_socials_box } from "data/socials";
import scrollTo from "gatsby-plugin-smoothscroll";

import {
  Container,
  Wrapper,
  LogoWrapper,
  Logo,
  MenuRight,
  ContactButton,
  ContactButtonText,
  SocialsWrapper,
  SocialLink,
} from "./styles";

export const Navbar = () => {
  const [menuScroll, setMenuScroll] = useState(false);
  useScrollPosition(({ currPos }) => {
    if (currPos.y < -60) {
      setMenuScroll(true);
    } else {
      setMenuScroll(false);
    }
  });

  return (
    <Container className={` ${menuScroll ? "scrolled" : ""}`}>
      <Wrapper>
        <LogoWrapper>
          <Logo to="/" title="Logo interactive" />
        </LogoWrapper>
        <MenuRight>
          <SocialsWrapper>
            {data_socials_box.map((social, i) => (
              <SocialLink
                key={i}
                href={social.href}
                icon={social.icon_black}
                hover={social.hover}
                title={social.title}
                target="_blank"
                rel="noopener"
              />
            ))}
          </SocialsWrapper>
          <ContactButton onClick={() => scrollTo("#landing_form")}>
            <ContactButtonText>Napisz do nas!</ContactButtonText>
          </ContactButton>
        </MenuRight>
      </Wrapper>
    </Container>
  );
};
