import { Navbar } from "components/LandingLayout";
import { NavbarNew } from "components/NavbarNew";
import { Player } from "components/Player/Player";
import { ScrollTop } from "components/ScrollTop";
import { Switcher } from "components/Switcher";
import { Transition } from "components/Transition";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ParallaxProvider } from "react-scroll-parallax";
import { Footer } from "sections/Footer";
import { SocialBox } from "sections/SocialBox";
import { GlobalWrapper } from "shared/Styles/Wrappers";
import { darkTheme, defaultTheme } from "shared/Themes";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { LocationContext } from "../contexts/locationContext";

const GlobalStyle = createGlobalStyle`
    body {
        background-color: ${({ theme }) => theme.colors.white}
    }
`;

const MainWrapper = styled.div`
    position: relative;
`;

const MainLayout = ({ children, pageContext, location }) => {
  const [isRealisationPage, setRealisationPage] = useState(null);
  const videoOpen = useSelector((state) => state.video.videoOpen);
  const themeState = useSelector((state) => state.theme.darkMode);

  useEffect(() => {
    setRealisationPage(location.pathname.includes("/realizacje/"));
  }, [location.pathname]);

  if (pageContext.layout === "404") {
    return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>;
  } else if (
    location.pathname.includes("/b2b") ||
    location.pathname.includes("/b2c") ||
    location.pathname.includes("/moto") ||
    location.pathname.includes("/deweloper")
  ) {
    return (
      <ParallaxProvider>
        <LocationContext.Provider value={location}>
          <ThemeProvider theme={defaultTheme}>
            <GlobalStyle/>
            <Navbar/>
            <Transition location={location}>
              <MainWrapper>
                <GlobalWrapper>{children}</GlobalWrapper>
                <Footer/>
              </MainWrapper>
            </Transition>
            <ScrollTop/>
            {videoOpen && <Player isOpen={videoOpen}/>}
          </ThemeProvider>
        </LocationContext.Provider>
      </ParallaxProvider>
    );
  } else {
    return (
      <ParallaxProvider>
        <LocationContext.Provider value={location}>
          <ThemeProvider theme={themeState ? darkTheme : defaultTheme}>
            <GlobalStyle/>
            <NavbarNew
              location={location}
              showNavbar={
                pageContext.showNavbar ||
                location.pathname.includes("/blog/") ||
                location.pathname.includes("/refundacja-ppwb") ||
                location.pathname.includes('/internacjonalizacja') ||
                location.pathname.includes('/realizacje/bloodlab') ||
                location.pathname.includes('/realizacje/best-shoes')
              }
              defaultTheme={themeState ? 1 : 0}
            />
            <SocialBox/>
            {videoOpen && <Player isOpen={videoOpen}/>}
            <Transition location={location}>
              <MainWrapper>
                <GlobalWrapper>{children}</GlobalWrapper>
                <Footer/>
              </MainWrapper>
            </Transition>
            {!isRealisationPage && <Switcher/>}
            <ScrollTop/>
          </ThemeProvider>
        </LocationContext.Provider>
      </ParallaxProvider>
    );
  }
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
