import React from "react";
import { Form } from "components/Form";
import { Container, Wrapper, StyledTitle, StyledText } from "./styles";
import { CONTACT_IFRAME_NAME } from "components/Form/form";

export const ContactForm = () => {
  return (
    <Container>
      <Wrapper>
        <StyledTitle>Nasza ekipa do zadań specjalnych</StyledTitle>
        <StyledText>
          Jesteśmy zespołem profesjonalistów, których cechuje ciągła gotowość
          poszukania rozwiązań budujących wartość Twojej firmy.
          <b> Nasza praca jest naszą pasją!</b> Posiadamy duże doświadczenie we
          współpracy i wspieraniu profesjonalistów takich jak Ty. Pozwól nam
          zrozumieć i poznać Twoje potrzeby. Na co czekasz?
        </StyledText>
        <div id="landing_form">
          <Form
            src={process.env.GATSBY_CONTACT_FORM_URL}
            name={CONTACT_IFRAME_NAME}
          />
        </div>
      </Wrapper>
    </Container>
  );
};
