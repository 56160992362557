import { SET_COLOR_MODE } from "./consts";

export const initialState = {
  darkMode: false,
};

export const theme = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_COLOR_MODE:
      return {
        ...state,
        darkMode: !state.darkMode,
      };
    default:
      return {
        ...state,
      };
  }
};
