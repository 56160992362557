import {Link}       from "gatsby";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import styled       from "styled-components";
import EnvIcon      from "../../assets/images/envelope.svg";
import LogoBlack    from "../../assets/images/logo.svg";
import LogoWhite    from "../../assets/images/logo_white.svg";

export const Wrapper = styled.div`
    max-width: 2560px;
    width: 100%;
    margin: 0 auto;
    padding: 0 48px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        padding: 0 24px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        height: 48px;
    }
`;

export const LogoWrapper = styled.div`
    width: 180px;
    position: relative;
    z-index: 3;
    display: flex;
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        width: auto;
    }
`;

export const MenuRight = styled.div`
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: 3;
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        width: auto;
    }
`;

export const Logo = styled(Link)`
    display: inline-block;
    width: 130px;
    height: 30px;
    background-image: url(${LogoWhite});
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    transition: 0.2s width ease;

    &.scrolled {
        background-image: url(${({logo}) => (logo ? LogoWhite : LogoBlack)});
    }

    &.show-navbar {
        background-image: url(${({logo}) => (logo ? LogoWhite : LogoBlack)});
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        width: 112px;
        height: 24px;
    }
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        width: 100px !important;
        height: 20px !important;
    }
`;

export const MenuWrapper = styled.div`
    display: flex;
    align-items: center;
    overflow: auto;
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        align-items: flex-start;
        padding-top: 48px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
    }
`;

export const MenuList = styled.ul`
    display: flex;
    align-items: center;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        width: 100%;
        flex: 1;
        padding: 48px 24px;
        flex-direction: column;
        align-items: flex-start;
        opacity: 0;
        visibility: hidden;
    }
`;

export const MenuItem = styled.li`
    margin: 0 18px;
    padding: 4px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        margin: 0 12px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop_small}) {
        margin: 0 8px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 18px;
        }
    }
`;

export const MenuLink = styled(Link)`
    color: #fff;
    font-weight: 600;
    font-size: 1.4rem;
    text-transform: uppercase;

    &.current {
        position: relative;

        &::after {
            content: "";
            background-color: currentColor;
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: -4px;
            left: 0;
        }
    }

    &:hover {
        transition: 0.2s color ease;
        color: #a27ff5;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
        font-size: 1.2rem;
    }
    @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.tablet_big}) {
        font-size: 4rem;
        font-weight: 800;
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
        font-size: 3rem;
        font-weight: 800;
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
        font-size: 2.4rem;
        font-weight: 800;
    }
`;

export const Dot = styled.span`
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        color: ${({theme}) => theme.colors.purple};
    }
`;

export const ContactButton = styled(AnchorLink)`
    background-color: #000;
    transition: 0.2s color ease;
    padding: 12px 24px;
    background-repeat: no-repeat;
    display: inline-block;
    background-color: ${({theme}) => theme.colors.purple};
    color: #fff;
    border: none;
    line-height: 1;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    transition: 0.2s all ease;
    border-radius: 5px;

    &:hover {
        background-color: ${({theme}) => theme.colors.button_hover};
        color: #fff;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        padding: 10px 18px;
        font-size: 1.2rem;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        background-image: url(${EnvIcon});
        width: 32px;
        height: 32px;
        padding: 0;
        background-size: 16px;
        background-position: center;
        min-width: 0px;
    }
`;

export const ContactButtonText = styled.span`
    color: #fff;
    font-weight: 800;
    font-size: 1.4rem;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 1.2rem;
    }
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        font-size: 1rem;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        display: none;
    }
`;

export const Line = styled.span`
    width: 100%;
    height: 2px;
    border-radius: 24px;
    background-color: #fff;
    transition: 0.2s all ease;

    &.scrolled {
        background-color: #000;
    }
`;

export const Hamburger = styled.div`
    width: 24px;
    height: 18px;
    flex-direction: column;
    justify-content: space-between;
    display: none;
    margin-left: 24px;
    transition: 0.2s all ease;
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        display: flex;
    }

    &.active {
        transform: rotate(45deg);

        span {
            &:nth-child(1) {
                transform: translateY(8px) rotate(90deg);
            }

            &:nth-child(2) {
            }

            &:nth-child(3) {
                opacity: 0;
            }
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        margin-left: 12px;
    }
`;

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    z-index: 1010;
    transition: 0.2s height ease;

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${({theme}) => theme.colors.white};
        transform: translateY(-80px);
        z-index: -1;
        transition: 0.2s transform ease;
        opacity: 0;
    }

    &.scrolled {
        height: 60px;
        box-shadow: 0px 4px 15px -3px ${({theme}) => theme.colors.menu_shadow};

        &:after {
            transform: translateY(0px);
            opacity: 1;
        }

        ${Logo} {
            width: 120px;
            height: 30px;
        }

        ${MenuLink} {
            color: ${({theme}) => theme.colors.black};

            &:hover {
                color: ${({theme}) => theme.colors.purple};
            }
        }

        ${ContactButton} {
            background-color: ${({theme}) => theme.colors.purple};
        }

        ${Line} {
            background-color: ${({theme}) => theme.colors.black};
        }
    }

    &.show-navbar {
        &:after {
            transform: translateY(0px);
            opacity: 1;
        }

        ${MenuLink} {
            color: ${({theme}) => theme.colors.black};

            &:hover {
                color: ${({theme}) => theme.colors.purple};
            }
        }

        ${ContactButton} {
            background-color: ${({theme}) => theme.colors.purple};
        }

        ${Line} {
            background-color: ${({theme}) => theme.colors.black};
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        height: 48px;
        &.scrolled {
            height: 48px;

            ${Logo} {
                height: 24px;
                width: 112px;
            }
        }
    }
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        flex-direction: column;
        transition: none;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #000;
            z-index: -1;
            transform: translateX(-100%);
        }

        &.active {
            height: 100vh;
            overflow: auto;

            &:after {
                height: 60px;
                background-color: #000;
                z-index: 2;
            }

            &:before {
                transform: translateX(0%);
                transition: 0.2s transform ease;
            }

            ${ContactButton} {
                background-color: ${({theme}) => theme.colors.purple};
            }

            ${Logo} {
                background-image: url(${LogoWhite});
            }

            ${Line} {
                background-color: #fff;
            }

            ${MenuWrapper} {
                opacity: 1;
                visibility: visible;
                transition: 0.2s transform ease;
            }

            ${MenuList} {
                opacity: 1;
                visibility: visible;
            }

            ${MenuItem} {
                transition: 0.4s all ease;
            }

            ${MenuLink} {
                color: #fff;
            }
        }
    }
`;
