import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  Container,
  Wrapper,
  StyledTitle,
  SlideTitle,
  SlideText,
  SlideWrapper,
  SlideArrow,
  Pagination,
} from "./styles";

const testimonials_data = [
  {
    title:
      "Agencja Interaktywna InteractiveVision to rzetelny i niezawodny partner w biznesie.",
    text:
      "W relacjach z Agencją czuliśmy się komfortowo, ponieważ mogliśmy się do niej zwrócić z każdym problemem związanym z marketingiem internetowym i promocją marki. Wspólnie stworzyliśmy wiele kreacji, które przyczyniły się do zwiększenia rozpoznawalności marki na lokalnym i ponadlokalnym rynku, a co za tym idzie do całościowego rozwoju biznesowego.",
  },
  {
    title: "Zlecone prace spełniły wszystkie nasze oczekiwania.",
    text:
      "Wszystkie nasze zlecenia były realizowane z zachowaniem należytej staranności, zarówno pod względem kryteriów jakościowych, jak i terminów realizacji. Agencja Interaktywna InteractiveVision dała się poznać jako pewny i rzetelny partner o elastycznym podejściu.",
  },
  {
    title:
      "Współpraca z Agencją Interaktywną InteractiveVision jest efektywna i sprawna.",
    text:
      "Jesteśmy zadowoleni z osiągniętych efektów. Wszystkie zlecenia były realizowane starannie i terminowo, z uwzględnieniem naszych potrzeb.",
  },
  {
    title:
      "Jesteśmy przekonani, że jeszcze wiele wspólnych realizacji przed nami.",
    text:
      "Wszystkie działania marketingowe prowadzone były z zachowaniem należytej staranności, a Agencja potrafi trafnie rozpoznać i zrealizować potrzeby partnera. Polecamy Agencję Interaktywną InteractiveVision jako partnera godnego zaufania.",
  },
  {
    title: "Efekt przekroczył nasze oczekiwania.",
    text:
      "Wszystkie etapy prac nad stroną www były realizowane zgodnie z objętym harmonogramem i cała realizacja przebiegła bardzo sprawnie i szybko. Jesteśmy pełni uznania dla rzetelnej i fachowej pracy Agencji Interaktywnej InteractiveVision.",
  },
];

const options = {
  loop: true,
  speed: 1000,
  spaceBetween: 64,
  autoplay: {
    delay: 4000,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },

  navigation: {
    nextEl: ".slide-next",
    prevEl: ".slide-prev",
  },
};

export const Testimonials = () => {
  return (
    <Container>
      <Wrapper>
        <StyledTitle color="#fff">Zobacz, co mówią o Nas inni. </StyledTitle>
        <SlideWrapper>
          <SlideArrow className="slide-prev" />
          <Swiper {...options}>
            {testimonials_data.map((slide, i) => (
              <SwiperSlide key={i}>
                <SlideTitle>{slide.title}</SlideTitle>
                <SlideText>{slide.text}</SlideText>
              </SwiperSlide>
            ))}
          </Swiper>
          <SlideArrow className="slide-next" />
        </SlideWrapper>
        <Pagination className="swiper-pagination" />
      </Wrapper>
    </Container>
  );
};
