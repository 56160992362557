import { DEFULT_CONTACT_MODAL } from "./consts"

export const initialState = {
    defaultModalOpen: false,
    projectManager: "",
}

export const modals = (state = initialState, { type, payload }) => {
switch(type) {
    case DEFULT_CONTACT_MODAL.open:
        return {
            ...state,
            projectManager: payload,
            defaultModalOpen: true
        }
    case DEFULT_CONTACT_MODAL.close:
        return {
            ...state,
            projectManager: "",
            defaultModalOpen: false
        }
    case DEFULT_CONTACT_MODAL.toggle:
        return {
            ...state,
            projectManager: "",
            defaultModalOpen: !state.defaultModalOpen
        }
    default:
        return state
    }
}
