import React from "react";
import scrollTo from "gatsby-plugin-smoothscroll";

import {
  Container,
  Wrapper,
  ImageWrapper,
  TextWrapper,
  StyledTitle,
  StyledText,
  StyledButton,
} from "./styles";

export const FullPhoto = ({ data, color }) => {
  return (
    <Container color={color}>
      <Wrapper>
        <ImageWrapper>{data.image}</ImageWrapper>
        <TextWrapper>
          <StyledTitle>{data.title}</StyledTitle>
          <StyledText>{data.text}</StyledText>
          <StyledButton onClick={() => scrollTo("#landing_form")}>
            {data.button}
          </StyledButton>
        </TextWrapper>
      </Wrapper>
    </Container>
  );
};
