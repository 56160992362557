import React, { useState } from "react";
import {
  Container,
  ImageBox,
  TextBox,
  OpenButton,
  CloseButton,
  SocialsWrapper,
  SocialLink,
  SocialImage,
  SocialText,
  SocialsTitle,
  SocialWrapperBox,
} from "./styles";

import { data_socials_box } from "../../data/socials";

export const SocialBox = () => {
  const [isSocialOpen, setSocialOpen] = useState(false);
  const [background, setBackground] = useState(false);

  const openBox = () => {
    setSocialOpen(true);
    document.body.classList.add("blocked");
  };

  const closeBox = () => {
    setSocialOpen(false);
    document.body.classList.remove("blocked");
  };

  const mouseEnter = (value) => {
    setBackground(value);
  };

  const mouseLeave = () => {
    setBackground(false);
  };

  return (
    <Container
      className={isSocialOpen ? "active" : null}
      isActive={isSocialOpen}
      background={background}
    >
      {!isSocialOpen && (
        <OpenButton onClick={openBox}>
          <ImageBox />
          <TextBox>Social media</TextBox>
        </OpenButton>
      )}
      <CloseButton
        className={isSocialOpen ? "active" : null}
        onClick={closeBox}
      />

      <SocialWrapperBox className={isSocialOpen ? "active" : null}>
        <SocialsTitle>Social Media</SocialsTitle>

        <SocialsWrapper>
          {data_socials_box.map((element, i) => (
            <SocialLink
              key={i}
              onMouseEnter={() => mouseEnter(element.background)}
              onMouseLeave={() => mouseLeave()}
              href={element.href}
              target="_blank"
              rel="noopener"
              title={element.title}
            >
              <SocialImage src={element.icon} alt={element.title} />
              <SocialText>{element.title}</SocialText>
            </SocialLink>
          ))}
        </SocialsWrapper>
      </SocialWrapperBox>
    </Container>
  );
};
