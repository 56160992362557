export const defaultTheme = {
  colors: {
    black: "#000",
    white: "#fff",
    purple: "#5A1CE4",
    gray: "#E7E7E7",
    gray_light: "#F9F9F9",
    gray_text: "#9A9A9A",
    gray_black: "#878787",
    input_border: "#BFBFBF",
    placeholder: "#535353",
    modal_overlay: "rgba(229, 229, 229,.9)",
    switcher: "#fff",
    swither_track: "#E9E9E9",
    switcher_shadow: "rgba(0, 0, 0, 0.15)",
    box_shadow: "rgba(98, 48, 208, 0.35)",
    menu_shadow: "rgba(0, 0, 0, 0.04);",
    button_hover: "#4900d2",
    links: "#0f0f0f",
    blog_background: "#F8F8F8",
    error_color: "#ff4c4c",
    contact_person: "rgba(200,200,200,0.1)",
    socialBox: "#000",
    category_menu: "#8D8D8D",
    logo_dot: "#5A1CE4",
  },
  fonts: {
    xxxl: "5.4rem",
    xxl: "3.6rem",
    xl: "2.8rem",
    l: "2.6rem",
    m: "2.4rem",
    s: "1.8rem",
    xs: "1.6rem",
    xxs: "1.4rem",
  },
  breakpoints: {
    laptop_big: "1601px",
    laptop: "1480px",
    laptop_small: "1281px",
    tablet_big: "1100px",
    tablet: "867px",
    tablet_small: "769px",
    phone_big: "601px",
    phone: "431px",
    phone_small: "374px",
  },
  customColors: {
    servicesBackground: "rgba(200, 200, 200, 0.1)",
    servicesCase: "#fff",
    jobRow: "#F9F9F9",
    justWhite: "#fff",
    formSubmit: "#5A1CE4",
    contactCard: "#757575",
    closeBtn: "%23000",
    contactCardBorder: "#C7C7C7",
    dotsColor: "rgb(93,0,226)",
    customGrayLight: "#F9F9F9",
  },
  darkMode: false,
};
