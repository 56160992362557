import React from "react";
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group";

export const Transition = ({ location, children }) => {
  const timeout = 300;

  // const getStyles = {
  //   entering: {
  //     position: "relative",
  //   },
  //   entered: {
  //     position: "relative",
  //     transition: `${timeout}ms all ease-in`,
  //     // transitionDelay: 200,
  //   },
  //   exiting: {
  //     position: "relative",
  //     transition: `${timeout}ms all ease-in`,
  //   },
  // };

  const getOpacityStyles = {
    entering: {
      opacity: 0,
    },
    entered: {
      opacity: 1,
      transition: `${timeout}ms all ease-in`,
    },
    exiting: {
      transition: `${timeout}ms all ease-out`,
      opacity: 0,
    },
  };

  return (
    <TransitionGroup>
      <ReactTransition
        key={location.pathname}
        timeout={{
          enter: timeout,
          exit: timeout,
        }}
      >
        {(status) => (
          <>
            <div style={{ ...getOpacityStyles[status] }}>
              <div>{children}</div>
            </div>
          </>
        )}
      </ReactTransition>
    </TransitionGroup>
  );
};
