import React, { useRef, useEffect } from "react";
import { Close, Container, Img, PlayerWrapper, VideoBox } from "./styles";
import CloseIcon from "assets/close.svg";
import Video from "assets/videos/home.mp4";
import { videoModalClose } from "store/video/actions";
import { useDispatch } from "react-redux";

const Player = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const handleCloseVideo = () => {
    dispatch(videoModalClose());
  };

  useEffect(() => {
    const current = ref.current;
    current.play();
  }, []);

  return (
    <Container>
      <PlayerWrapper>
        <VideoBox
          ref={ref}
          src={Video}
          loop
          playsInline
          allowFullScreen
          controls
        />
      </PlayerWrapper>
      <Close onClick={handleCloseVideo}>
        <Img src={CloseIcon} />
      </Close>
    </Container>
  );
};

export { Player };
