import styled from "styled-components";
import { GlobalWrapper, SectionWrapper } from "shared/Styles/Wrappers";
import { Title, Text } from "../Typography/typography";
import Arrow from "assets/images/arr_right_w.svg";

export const Container = styled(GlobalWrapper)`
  padding: 96px 0;
  background-color: #000;
  color: #fff;
`;

export const Wrapper = styled(SectionWrapper)`
  max-width: 800px;
  text-align: center;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    max-width: 600px;
  }
`;

export const StyledTitle = styled(Title)`
  width: 100%;
  padding-bottom: 36px;
  margin-bottom: 48px;
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 100px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.purple};
  }
`;

export const SlideWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const SlideTitle = styled.h3`
  font-size: 2.2rem;
  line-height: 1.2;
  font-weight: 600;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-bottom: 36px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 1.6rem;
    max-width: 400px;
  }
`;

export const SlideText = styled(Text)`
  display: block;
  flex-shrink: 0;
  color: #fff;
`;

export const SlideArrow = styled.div`
  width: 38px;
  height: 38px;
  background-color: ${({ theme }) => theme.colors.purple};
  background-image: url(${Arrow});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px;
  border-radius: 100%;
  flex-shrink: 0;
  position: absolute;
  top: 50%;
  right: calc(100% + 112px);
  transform: translateY(-50%) rotate(180deg);
  cursor: pointer;
  z-index: 10;
  &.slide-next {
    right: auto;
    left: calc(100% + 112px);
    transform: translateY(-50%) rotate(0deg);
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    right: calc(100% + 64px);
    width: 28px;
    height: 28px;
    background-size: 8px;

    &.slide-next {
      left: calc(100% + 64px);
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const Pagination = styled.div`
  margin-top: 64px;
  position: relative;
  .swiper-pagination-bullet {
    background-color: #fff;
    opacity: 1;
    width: 7px;
    height: 7px;
    margin: 0 8px;
    opacity: 0.2;
    &.swiper-pagination-bullet-active {
      background-color: ${({ theme }) => theme.colors.purple};
      opacity: 1;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 36px;
  }
`;
