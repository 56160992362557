export const darkTheme = {
  colors: {
    black: "#fff",
    white: "rgb(17,16,17)",
    purple: "#5A1CE4",
    gray: "#4c4c4c",
    gray_light: "#333",
    gray_text: "#9A9A9A",
    gray_black: "#878787",
    input_border: "#979797",
    placeholder: "#f2f2f2",
    modal_overlay: "rgba(17, 16, 17, .9)",
    switcher: "#323232",
    swither_track: "#E9E9E9",
    switcher_shadow: "rgba(255, 255, 255, 0.15)",
    box_shadow: "rgba(255, 255, 255, 0.35)",
    menu_shadow: "rgba(255, 255, 255, 0.04);",
    button_hover: "#4c4c4c",
    links: "#8f8f8f",
    blog_background: "#282828",
    error_color: "#ff4c4c",
    contact_person: "rgba(51, 51, 51, 0.5)",
    socialBox: "#5A1CE4",
    category_menu: "#8D8D8D",
    logo_dot: "#ffffff",
  },
  fonts: {
    xxxl: "5.4rem",
    xxl: "3.6rem",
    xl: "2.8rem",
    l: "2.6rem",
    m: "2.4rem",
    s: "1.8rem",
    xs: "1.6rem",
    xxs: "1.4rem",
  },
  breakpoints: {
    laptop_big: "1601px",
    laptop: "1480px",
    laptop_small: "1281px",
    tablet_big: "1100px",
    tablet: "867px",
    tablet_small: "769px",
    phone_big: "601px",
    phone: "431px",
    phone_small: "374px",
  },
  customColors: {
    servicesBackground: "transparent",
    servicesCase: "#2b2b2b",
    jobRow: "#232323",
    justWhite: "#fff",
    formSubmit: "#fff",
    contactCard: "#d6d6d6",
    closeBtn: "%23fff",
    contactCardBorder: "rgba(255, 255, 255, 0.1)",
    dotsColor: "rgba(255,255,255,.5)",
    customGrayLight: "rgba(51, 51, 51,.5)",
  },
  darkMode: true,
};
