import { combineReducers } from "redux";
import { modals } from "./modals";
import { theme } from "./theme";
import { video } from "./video";

export const rootReducer = combineReducers({
  modals,
  theme,
  video,
});
