import React from "react";
import {
  Container,
  Wrapper,
  Column,
  StyledButton,
  StyledTitle,
  StyledText,
} from "./styles";

import { useDispatch } from "react-redux";
import { videoModalOpen } from "store/video/actions";

export const Hero = ({ data }) => {
  const dispatch = useDispatch();
  const handleOpenVideo = () => dispatch(videoModalOpen());

  return (
    <Container>
      <Wrapper>
        <Column>
          <StyledTitle as="h1">{data.title}</StyledTitle>
          <StyledText>{data.text}</StyledText>
          <StyledButton onClick={handleOpenVideo}>
            Zobacz showreel!
          </StyledButton>
        </Column>
        <Column>{data.image}</Column>
      </Wrapper>
    </Container>
  );
};
