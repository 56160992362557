import BehanceIcon from "assets/images/behance.svg";
import FacebookIcon from "assets/images/facebook.svg";
import InstagramIcon from "assets/images/instagram.svg";
import DribbleIcon from "assets/images/dribble.svg";
import BehanceHover from "assets/images/behance-hover.svg";
import FacebookHover from "assets/images/facebook-hover.svg";
import InstagramHover from "assets/images/instagram-hover.svg";
import DribbleHover from "assets/images/dribble-hover.svg";
import BehanceBlack from "assets/images/behance-black.svg";
import FacebookBlack from "assets/images/facebook-black.svg";
import InstagramBlack from "assets/images/instagram-black.svg";
import DribbleBlack from "assets/images/dribble-black.svg";

export const data_socials_box = [
  {
    title: "Behance",
    icon: BehanceIcon,
    icon_black: BehanceBlack,
    hover: BehanceHover,
    background: "#3163F6",
    href: "https://www.behance.net/interactivevisionpl",
  },
  {
    title: "Facebook",
    icon: FacebookIcon,
    icon_black: FacebookBlack,
    hover: FacebookHover,
    background: "#3b5998",
    href: "https://pl-pl.facebook.com/interactivevision/?hc_location=ufi",
  },
  {
    title: "Instagram",
    icon: InstagramIcon,
    icon_black: InstagramBlack,
    hover: InstagramHover,
    background: "#8143B2",
    href: "https://www.instagram.com/interactivevision/?hl=pl",
  },
  {
    title: "Dribbble",
    icon: DribbleIcon,
    icon_black: DribbleBlack,
    hover: DribbleHover,
    background: "#DC5F94",
    href: "https://dribbble.com/intervi",
  },
];
