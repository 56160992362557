import styled, {css} from "styled-components";
import {Link} from "gatsby";
import {AnchorLink} from "gatsby-plugin-anchor-links";

const default_styles = css`
    display: inline-block;
    padding: 12px 24px;
    background-color: ${({theme}) => theme.colors.purple};
    color: #fff;
    min-width: 140px;
    border: none;
    line-height: 1;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    transition: 0.2s all ease;
    border-radius: 5px;

    &:hover {
        background-color: ${({theme}) => theme.colors.button_hover};
        color: #fff;
    }

    ${({color}) =>
            color === "black"
            ? css`
                background-color: transparent;
                border: 1px solid ${({theme}) => theme.colors.black};
                color: ${({theme}) => theme.colors.black};

                &:hover {
                    background-color: ${({theme}) => theme.colors.purple};
                    color: #fff;
                    border: 1px solid ${({theme}) => theme.colors.purple};
                    box-shadow: none;
                    transition: 0.2s ease;
                }
            `
            : null}
    ${({color}) =>
            color === "white"
            ? css`
                background-color: transparent;
                border: 1px solid ${({theme}) => theme.customColors.justWhite};
                color: ${({theme}) => theme.customColors.justWhite};
                transition: 0.2s ease;

                &:hover {
                    background-color: ${({theme}) => theme.customColors.justWhite};
                    color: #000;
                    box-shadow: none;
                    transition: 0.2s ease;
                }
            `
            : null} ${({color}) =>
            color === "transparent"
            ? css`
                background-color: ${({theme}) => theme.customColors.justWhite};
                border: 1px solid ${({theme}) => theme.customColors.justWhite};
                color: ${({theme}) => theme.colors.purple};
                transition: 0.2s ease;

                &:hover {
                    background-color: ${({theme}) => theme.customColors.button_hover};
                    box-shadow: none;
                    transition: 0.2s ease;
                }
            `
            : null} @media screen and(max-width: ${({theme}) =>
        theme.breakpoints.laptop}) {
    font-size: 1.2rem;
    padding: 10px 18px;
    min-width: 100px;
}
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        padding: 8px 12px;
    }
`;

export const InsideLink = styled(Link)`
    ${default_styles}
`;

export const StyledAnchorLink = styled(AnchorLink)`
    ${default_styles}
`;

export const OutsideLink = styled.a`
    ${default_styles}
`;

export const NormalButton = styled.button`
    ${default_styles}
`;
