import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 72px;
`;

export const CenteredHeader = styled.div`
  display: flex;
  justify-content: center;
  margin: 60px 0;
  text-align: center;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin-bottom: 30px;
  }
`;

export const IframeWrapper = styled.div``;

export const Iframe = styled.iframe`
  width: 100%;
  display: block;
  border: none;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
  }
`;
