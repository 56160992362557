import React                                                      from "react";
import {InsideLink, NormalButton, OutsideLink, StyledAnchorLink,} from "./styles";

export const Button = (props) => {
  return (
    <>
      {
        props.to && (
          <InsideLink {...props}>{props.children}</InsideLink>
        )
      }
      {
        props.anchor && (
          <StyledAnchorLink to={props.anchor}>{props.children}</StyledAnchorLink>
        )
      }
      {
        props.href && (
          <OutsideLink {...props}>{props.children}</OutsideLink>
        )}
      {
        !props.to && !props.href && !props.anchor && (
          <NormalButton {...props}>{props.children}</NormalButton>
        )
      }
    </>
  );
};
