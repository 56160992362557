import React from "react";
import scrollTo from "gatsby-plugin-smoothscroll";

import {
  Container,
  Wrapper,
  Row,
  StyledButton,
  StyledTitle,
  StyledText,
  ListWrapper,
  List,
  ListTitle,
  StyledListItem,
} from "./styles";

export const ColumnTextBox = ({ data }) => {
  return (
    <Container>
      <Wrapper>
        <Row>
          <StyledTitle>{data.title}</StyledTitle>
          {data.text && <StyledText>{data.text}</StyledText>}
          {data.list && (
            <ListWrapper>
              <ListTitle>{data.list.title}</ListTitle>
              <List>
                {data.list.list.map((element, i) => (
                  <StyledListItem key={i}>{element}</StyledListItem>
                ))}
              </List>
            </ListWrapper>
          )}
          <StyledButton onClick={() => scrollTo("#landing_form")}>
            {data.button}
          </StyledButton>
        </Row>
        <Row>{data.image}</Row>
      </Wrapper>
    </Container>
  );
};
