import React, { useState, useEffect } from "react";
import { ArrowBox } from "./styles";

const triggerClick = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const ScrollTop = () => {
  const [ShowScrollButton, SetShowScrollButton] = useState(false);

  const listener = () => {
    var scroller = window.scrollY;

    if (scroller > 400) {
      SetShowScrollButton(true);
    } else {
      SetShowScrollButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  });

  return (
    <ArrowBox
      onClick={() => triggerClick()}
      className={ShowScrollButton ? "show" : ""}
    ></ArrowBox>
  );
};

export { ScrollTop };
