import React from "react";
import scrollTo from "gatsby-plugin-smoothscroll";

import {
  Container,
  Wrapper,
  Column,
  TextWrapper,
  StyledButton,
  StyledTitle,
  StyledText,
  ListWrapper,
  List,
  ListTitle,
  StyledListItem,
} from "./styles";

export const TextBox = ({ data, reverse, background }) => {
  return (
    <Container background={background}>
      <Wrapper reverse={reverse}>
        <Column reverse={reverse}>
          <TextWrapper>
            <StyledTitle>{data.title}</StyledTitle>
            {data.list && (
              <ListWrapper>
                <ListTitle>{data.list.title}</ListTitle>
                <List>
                  {data.list.list.map((element, i) => (
                    <StyledListItem key={i}>{element}</StyledListItem>
                  ))}
                </List>
              </ListWrapper>
            )}
            {data.text && <StyledText margin="12px">{data.text}</StyledText>}
            <StyledButton onClick={() => scrollTo("#landing_form")}>
              {data.button}
            </StyledButton>
          </TextWrapper>
        </Column>
        <Column>{data.image}</Column>
      </Wrapper>
    </Container>
  );
};
