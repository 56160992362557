import React from "react";
import VideoUrl from "assets/videos/video.mp4";
import scrollTo from "gatsby-plugin-smoothscroll";

import {
  Container,
  Wrapper,
  TextWrapper,
  Video,
  StyledTitle,
  StyledButton,
} from "./styles";

export const FooterVideo = () => {
  return (
    <Container>
      <Wrapper>
        <Video src={VideoUrl} muted loop autoPlay playsInline />
        <TextWrapper>
          <StyledTitle>
            Chcesz się przekonać, co zaproponujemy Twojej firmie?
          </StyledTitle>
          <StyledButton onClick={() => scrollTo("#landing_form")}>
            Napisz do nas!
          </StyledButton>
        </TextWrapper>
      </Wrapper>
    </Container>
  );
};
