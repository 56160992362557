import { VIDEO_MODAL } from "./consts";

export const initialState = {
  videoOpen: false,
};

export const video = (state = initialState, { type, payload }) => {
  switch (type) {
    case VIDEO_MODAL.open:
      return {
        ...state,
        videoOpen: true,
      };
    case VIDEO_MODAL.close:
      return {
        ...state,
        videoOpen: false,
      };

    default:
      return state;
  }
};
