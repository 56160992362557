import styled from "styled-components";
import { GlobalWrapper, SectionWrapper } from "shared/Styles/Wrappers";
import { Button } from "components/Buttons";
import { Title, Text, ListItem } from "../Typography/typography";

export const Container = styled(GlobalWrapper)`
  padding: 124px 0;
  background-color: ${({ background }) => (background ? background : "#fff")};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 72px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 24px 0;
  }
`;

export const TextWrapper = styled.div`
  max-width: 560px;
  width: 100%;
`;

export const Wrapper = styled(SectionWrapper)`
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  width: 50%;
  display: flex;
  align-items: center;

  &:nth-child(1) {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: ${({ reverse }) => (reverse ? "0 0 0 48px " : "0 48px 0 0 ")};
  }
  &:nth-child(2) {
    align-items: flex-start;
    justify-content: flex-start;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100% !important;
    &:nth-child(1) {
      padding: 0 !important;
      text-align: center;
      margin-bottom: 36px;
    }
  }
`;

export const StyledTitle = styled(Title)``;

export const StyledButton = styled(Button)``;

export const StyledText = styled(Text)`
  margin: 48px 0;
`;

export const List = styled.ul`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledListItem = styled(ListItem)``;

export const ListWrapper = styled.div`
  margin: 48px 0;
`;

export const ListTitle = styled(Text)`
  font-weight: 600;
  margin-bottom: 24px;
`;
