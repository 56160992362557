import Badge                                                                                                                                                                                                                    from "assets/images/google-badge.svg"
import {data_socials_box}                                                                                                                                                                                                       from "data/socials";
import React                                                                                                                                                                                                                    from "react";
import {GoogleBadge}                                                                                                                                                                                                            from "./styles";
import {Address, AddressColumn, AddressText, AddressTitle, CenteredRow, Column, Container, Copyrights, FooterLogo, PhoneNumber, Row, SmallTitle, SocialIcon, SocialLink, Socials, SocialWrapper, Title, TitleWrapper, Wrapper,} from "./styles";

const data_footer = {
  phone:    "693 381 393",
  email:    "hello@interactivevision.pl",
  street_1: "ul. Przemysłowa 14B",
  city_1:   "Rzeszów 35-105",
  hours:    "Godziny otwarcia: 09:00 - 17:00",
  company:  <>InteractiveVision Sp. z o.o.</>,
  title_2:  "InteractiveVision Sp. z o.o.",
  nip:      "NIP: 8133839510",
  regon:    "REGON: 386180299",
  krs:      "KRS: 0000843954",
  copyrights:
            "Copyright © 2009 - 2022 | Agencja Interaktywna InteractiveVision - Wszystkie prawa zastrzeżone",
};

export const Footer = () => {
  return (
    <Container>
      <Wrapper>
        {/*<Row>
          <Column>
            <TitleWrapper className="purple">
              <Title>
                Odkrywaj z nami swoje możliwości i&#160;razem sięgnijmy gwiazd
              </Title>
            </TitleWrapper>
          </Column>
          <Column>
            <Address>
              <AddressTitle>{data_footer.title_2}</AddressTitle>
              <AddressColumn>
                <AddressText>{data_footer.street_1}</AddressText>
                <AddressText>{data_footer.city_1}</AddressText>
                <AddressText>{data_footer.hours}</AddressText>
              </AddressColumn>
              <AddressColumn>
                <AddressText>{data_footer.nip}</AddressText>
                <AddressText>{data_footer.regon}</AddressText>
                <AddressText>{data_footer.krs}</AddressText>
              </AddressColumn>
            </Address>
          </Column>
        </Row>*/}
        <CenteredRow>
          <SmallTitle>Skontaktuj się z nami:</SmallTitle>
          <PhoneNumber
            href={`mailto:${data_footer.email}`}
            title="telefon kontaktowy"
          >
            {data_footer.email}
          </PhoneNumber>
          <Row className="icons-block">
            <GoogleBadge target="_blank">
              <img src={Badge} alt="Odznaka partnera Google"/>
            </GoogleBadge>
            <Socials>
              {data_socials_box.map((item, i) => (
                <SocialLink
                  key={`${item.title}_${i}`}
                  href={item.href}
                  target="_blank"
                  rel="noopener"
                  title={item.title}
                >
                  <SocialWrapper>
                    <SocialIcon
                      src={item.icon}
                      alt=""
                      onMouseOver={(e) => (e.currentTarget.src = item.hover)}
                      onMouseOut={(e) => (e.currentTarget.src = item.icon)}
                    />
                  </SocialWrapper>
                </SocialLink>
              ))}
            </Socials>
            <FooterLogo to="/" title="Logo InteractiveVision"/>
          </Row>
        </CenteredRow>
        <Copyrights color={"white"} size={1.2}>
          {data_footer.copyrights}
        </Copyrights>
      </Wrapper>
    </Container>
  );
};
