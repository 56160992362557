import styled, { css } from "styled-components";

const responsive = css`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    max-width: 1030px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    max-width: 836px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 683px;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    max-width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
  }
`;

export const GlobalWrapper = styled.div`
  max-width: 100vw;
  width: 100%;
`;

export const SectionWrapper = styled.div`
  max-width: 1400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  ${responsive}
`;

export const TextWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  @media screen and (max-width: 1367px) {
    max-width: 860px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    max-width: 760px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 683px;
    padding-left: 24px;
    padding-right: 24px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    max-width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }
`;
